import { FeedType, MainPageType } from './models';
import { TFunction } from '@wix/yoshi-flow-editor';

type HeaderNavigationItem = {
  id: FeedType;
  title: string;
  isActive: boolean;
  feedType: FeedType | null;
};

type GenerateHeaderNavigationItemsDto = {
  t: TFunction;
  mainPageType: MainPageType;
  activeFeedType: string;
};

export const generateHeaderNavigationItems = ({
  t,
  mainPageType,
  activeFeedType,
}: GenerateHeaderNavigationItemsDto): HeaderNavigationItem[] => {
  const isMainPageCategories = mainPageType === MainPageType.Categories;

  const categoriesItem: HeaderNavigationItem = {
    id: FeedType.Categories,
    title: t('tabs.categories'),
    isActive: activeFeedType === FeedType.Categories,
    feedType: isMainPageCategories ? null : FeedType.Categories,
  };

  const postsItem: HeaderNavigationItem = {
    id: FeedType.Posts,
    title: t('tabs.posts'),
    isActive: activeFeedType === FeedType.Posts,
    feedType: isMainPageCategories ? FeedType.Posts : null,
  };

  const myPostsItem: HeaderNavigationItem = {
    id: FeedType.MyPosts,
    title: t('tabs.my-posts'),
    isActive: activeFeedType === FeedType.MyPosts,
    feedType: FeedType.MyPosts,
  };

  return [
    isMainPageCategories ? categoriesItem : postsItem,
    isMainPageCategories ? postsItem : categoriesItem,
    myPostsItem,
  ];
};
