import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Link from '../link/internal-link';
import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './post-title.scss';
import { DISCUSSION } from '@wix/communities-forum-client-commons/dist/src/constants/post-types';

const renderCategoryLink = (category, Link, t) => {
  const to = `/${category.slug}`;
  return (
    <div className={styles.link}>
      {t('post-list-item.title-in-category')}&nbsp;
      <Link to={to} className={classNames('forum-link-hover-color')}>
        <span className={styles.categoryLink}>{category.label}</span>
      </Link>
    </div>
  );
};

const PostSubTitle = ({ className, category, t, type, showCategoryLink }) => {
  return (
    <div className={classNames(className, styles[type], styles.container)}>
      {showCategoryLink && renderCategoryLink(category, Link, t)}
    </div>
  );
};

PostSubTitle.propTypes = {
  className: PropTypes.string,
  category: PropTypes.object,
  post: PropTypes.object.isRequired,
  t: PropTypes.func,
  type: PropTypes.string,
  showCategoryLink: PropTypes.bool,
};

PostSubTitle.defaultProps = {
  showCategoryLink: false,
};

PostSubTitle.LARGE = 'large';
PostSubTitle.SEARCH = 'search';
PostSubTitle.MOBILE = 'mobile';

export default flowRight(withTranslate)(PostSubTitle);
