import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/yoshi-flow-editor/tpa-settings';

export type IStylesParams = {};

export default createStylesParams<IStylesParams>({});
