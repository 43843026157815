import { flowRight, isNil, every } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import Page from '../../components/page';
import CategoryDescription from '../../components/category-description';
import ForumDescription from '../../components/forum-description';
import PostList from '../../components/post-list';
import {
  getPostsByCategoryIdAndSiteMemberIdAndPage,
  getSortedAllPosts,
  getSortedPostsByCategoryIdAndSiteMemberId,
} from '../../selectors/post-selectors';
import AccountSuspended from '../../components/account-suspended';
import {
  LoggedInNoAccessEmptyState,
  LoggedInNoAccessWithPlansEmptyState,
  NoCategoriesEmptyState,
  NotLoggedInNoPlansEmptyState,
} from '../../components/no-categories-empty-states';
import RestrictionPrivateMember from '../../components/restriction-private-member';
import NotFound from '../../components/not-found';
import NoPosts from '../../components/no-posts';
import SubcategoryList from '../subcategory-list';
import { FullHeightLayout, SimpleLayout, EmptyLayout } from '../../components/layout';
import { POSTS_PER_PAGE } from '../../constants/pagination';
import { LAYOUT_TYPE_PINBOARD } from '@wix/communities-forum-client-commons/dist/src/constants/layout-types';
import { FILTER_SHOW_ALL } from '../../constants/filtering';
import withAuth from '../../hoc/with-auth';
import forDevice from '../../hoc/for-device';
import withDeviceType from '../../hoc/with-device-type';
import {
  getAllCategories,
  getCategoryBySlug,
  getSubcategories,
} from '../../../common/selectors/categories-selectors';
import { getIsCategoryLoading } from '../../selectors/is-loading-selectors';
import { getIsPostsLoaded } from '../../selectors/is-loaded-selectors';
import { getLayoutType } from '../../selectors/layout-selectors';
import { getCategoryPageSorting } from '../../selectors/sorting-selectors';
import { getRouteParams } from '../../../common/router/router-selectors';
import { getEntityCount } from '../../selectors/pagination-selectors';
import { getIsHeaderEnabled } from '../../selectors/app-settings-selectors';
import { isMemberAreaActive } from '@wix/communities-forum-client-commons/dist/src/selectors/member-area-selectors';
import { getCategoryFilter, getPostTypeFilter } from '../../selectors/filter-selectors';
import { UNCATEGORIZED_POSTS } from '../../reducers/pagination';
import LargeSpacingLayout from '../../components/layout/large-spacing-layout';
import PrivateCategoryRestriction from '../../components/private-category-restriction/private-category-restriction';
import withPermissions from '../../hoc/with-permissions';
import {
  isPublic,
  isMembersOnly,
} from '@wix/communities-forum-client-commons/dist/src/services/category-privacy-utils';
import { hasCategoryPaidPlans, hasPaidPlans } from '../../selectors/paid-plans-selectors';
import NotLoggedInWithPlansEmptyState from '../../components/no-categories-empty-states/not-logged-in-with-plans-empty-state';
import { HorizontalSeparator } from '../../components/separator';
import { isEditor, isPreview } from '../../../common/store/basic-params/basic-params-selectors';
import { createFeedTypeQueryParam, getFeedType } from '../header-navigation';
import withExperiment from '../../hoc/with-experiment';
import {
  EXPERIMENT_FORUM_TABS,
  EXPERIMENT_INCREASE_POST_LIST_ITEM_COUNT,
} from '@wix/communities-forum-client-commons/dist/src/constants/experiments';

const Layout = forDevice(EmptyLayout, LargeSpacingLayout);
const NotFoundLayout = forDevice(FullHeightLayout, SimpleLayout);

const resetPage = () => ({ page: 1 });
const incrementPage = state => ({ page: state.page + 1 });
const isCategoryNotFound = (c, categorySlug) => categorySlug !== '' && c._id === null;

export class CategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: !props.isMobile && props.layoutType !== LAYOUT_TYPE_PINBOARD ? props.page : 1,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {
    this.props.emitCloseCategory(this.props.category._id);
  }

  UNSAFE_componentWillReceiveProps({ categorySlug, category, page, sortingType }) {
    const currentCategorySlug = this.props.categorySlug;

    if (this.props.isLiveSite && currentCategorySlug !== categorySlug) {
      this.props.emitCloseCategory(this.props.category._id);
      this.setState(resetPage, () => this.fetchPosts(category._id));
    } else if (this.state.page !== page && this.props.page !== page) {
      this.setState({ page }, () => this.fetchPosts(category._id));
    } else if (
      this.props.layoutType === LAYOUT_TYPE_PINBOARD &&
      this.props.sortingType !== sortingType
    ) {
      this.setState(resetPage);
    }
  }

  componentDidUpdate({ isAuthenticated, isBlocked, isPrivate }) {
    if (
      this.props.isAuthenticated !== isAuthenticated ||
      this.props.isBlocked !== isBlocked ||
      this.props.isPrivate !== isPrivate
    ) {
      this.fetchPosts();
    }
  }

  fetchPosts = (
    categoryId = this.props.category._id,
    page = this.state.page,
    siteMemberId = this.props.siteMemberId,
  ) => {
    const { isPostCountIncreaseEnabled } = this.props;

    if (!this.shouldShowPosts()) {
      return;
    }

    // @TODO update POSTS_PER_PAGE constant to 30, when experiment is merged
    const pageSize = isPostCountIncreaseEnabled ? 30 : POSTS_PER_PAGE;

    this.props.fetchPosts({
      siteMemberId,
      categoryId,
      page,
      pageSize,
    });
  };

  shouldShowPosts() {
    const {
      category,
      isAuthenticated,
      isBlocked,
      isPrivate,
      isMemberAreaActive,
      showAsMainPage,
    } = this.props;
    if (showAsMainPage) {
      return true;
    }

    const hasPrivateSocialProfile = isMemberAreaActive && isPrivate;
    const canSeePrivateCategory = isAuthenticated && !isBlocked && !hasPrivateSocialProfile;
    return !isMembersOnly(category) || canSeePrivateCategory;
  }

  changePage = page => {
    this.props.navigateWithinForum(this.buildPageUrl(page));
  };

  handleLoad = () => this.setState(incrementPage, this.fetchPosts);

  areCategoriesRestricted(categories) {
    return every(categories, c => !this.props.can('create-post', 'category', c));
  }

  renderPosts() {
    const {
      isBlocked,
      currentPagePosts,
      allPosts,
      entityCount,
      category,
      isLoading,
      isPostsLoaded,
      isAuthenticated,
      isPrivate,
      isMemberAreaActive,
      categorySlug,
      can,
      hasActiveFilter,
      allCategories,
      hasPP,
      hasCategoryPP,
      showMemberPosts,
      showAsMainPage,
      isForumTabsEnabled,
    } = this.props;
    const hasPosts = allPosts.length;

    if (isBlocked) {
      return <AccountSuspended />;
    } else if (!allCategories.length) {
      return <NoCategoriesEmptyState hasPaidPlans={hasPP} />;
    } else if (!isPublic(category) && !isAuthenticated) {
      if (hasCategoryPP) {
        return (
          <NotLoggedInWithPlansEmptyState
            returnTo={categorySlug}
            paidPlanIds={category.groups}
            categoryId={category._id}
          />
        );
      } else {
        return <NotLoggedInNoPlansEmptyState />;
      }
    } else if (isMembersOnly(category) && isMemberAreaActive && isPrivate) {
      return <RestrictionPrivateMember />;
    } else if (isAuthenticated && categorySlug && !can('read', 'category', category)) {
      if (hasCategoryPP) {
        return (
          <LoggedInNoAccessWithPlansEmptyState
            returnTo={categorySlug}
            paidPlanIds={category.groups}
            categoryId={category._id}
          />
        );
      } else if (allCategories.length > 1) {
        return <PrivateCategoryRestriction />;
      } else {
        return <LoggedInNoAccessEmptyState />;
      }
    } else if (
      !hasPosts &&
      !isLoading &&
      !categorySlug &&
      this.areCategoriesRestricted(allCategories)
    ) {
      return <NoCategoriesEmptyState hasPaidPlans={hasPP} />;
    } else if (!hasPosts && !isLoading && !hasActiveFilter) {
      return <NoPosts noMemberPosts={showMemberPosts} category={category} type="no_posts" />;
    } else {
      return (
        <PostList
          page={this.props.page}
          entityCount={entityCount}
          allPosts={allPosts}
          changePage={this.changePage}
          buildPageUrl={this.buildPageUrl}
          loadMore={this.handleLoad} // eslint-disable-line react/jsx-handler-names
          currentPagePosts={currentPagePosts}
          isLoading={isLoading}
          showLoaderInLoadMore={isLoading}
          category={category}
          isLoaded={isPostsLoaded}
          selectedCategorySlug={categorySlug}
          hasActiveFilter={hasActiveFilter}
          showCategoryFilter={isForumTabsEnabled && showMemberPosts}
          showMemberPosts={showMemberPosts}
        />
      );
    }
  }

  buildPageUrl = (page = 1) => {
    const { categorySlug, feedType, showAsMainPage } = this.props;
    const isFirstPage = page === 1;
    const queryParams = feedType ? `?${createFeedTypeQueryParam(feedType)}` : '';

    if (categorySlug && !showAsMainPage) {
      return isFirstPage
        ? `/${categorySlug}${queryParams}`
        : `/${categorySlug}/p-${page}${queryParams}`;
    } else {
      return isFirstPage ? `/${queryParams}` : `/p-${page}${queryParams}`;
    }
  };

  renderPageDescription = () => {
    const { showAsMainPage, category, isHeaderEnabled } = this.props;
    return (
      <React.Fragment>
        {showAsMainPage ? (
          <ForumDescription />
        ) : (
          <CategoryDescription category={category} isCoverVisible={isHeaderEnabled} />
        )}
      </React.Fragment>
    );
  };

  render() {
    const {
      category,
      categorySlug,
      subcategories,
      isMobile,
      isHeaderEnabled,
      showAsMainPage,
    } = this.props;

    if (isCategoryNotFound(category, categorySlug)) {
      return (
        <Page>
          <NotFoundLayout>
            <NotFound />
          </NotFoundLayout>
        </Page>
      );
    }

    const pageDescription = this.renderPageDescription();
    return (
      <Page smallSeparatorTop={isHeaderEnabled && isMobile}>
        <Layout spacingBetweenAll={!!pageDescription}>
          {pageDescription}
          {!showAsMainPage && <SubcategoryList categoryId={category._id} />}
          {!showAsMainPage && isMobile && subcategories.length !== 0 && (
            <HorizontalSeparator isLarge />
          )}
          {this.renderPosts()}
        </Layout>
      </Page>
    );
  }
}

CategoryPage.propTypes = {
  categorySlug: PropTypes.string,
  fetchPosts: PropTypes.func.isRequired,
  emitCloseCategory: PropTypes.func.isRequired,
  categoryId: PropTypes.string,
  location: PropTypes.object,
  setIsLoaded: PropTypes.func.isRequired,
  navigateWithinForum: PropTypes.func,
  entityCount: PropTypes.number,
  page: PropTypes.number,
  allPosts: PropTypes.array,
  currentPagePosts: PropTypes.array,
  subcategories: PropTypes.array,
  category: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  isBlocked: PropTypes.bool,
  isPrivate: PropTypes.bool,
  params: PropTypes.object,
  t: PropTypes.func,
  newPostId: PropTypes.string,
  isLoading: PropTypes.bool,
  isPostsLoaded: PropTypes.bool,
  layoutType: PropTypes.number,
  isMemberAreaActive: PropTypes.bool,
  isHeaderEnabled: PropTypes.bool,
  showAllPosts: PropTypes.bool.isRequired,
  showAsMainPage: PropTypes.bool.isRequired,
  sortingType: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  hasActiveFilter: PropTypes.bool,
  allCategories: PropTypes.bool.isRequired,
  hasPP: PropTypes.bool,
  hasCategoryPP: PropTypes.bool,
  isLiveSite: PropTypes.bool,
  siteMemberId: PropTypes.string,
  showMemberPosts: PropTypes.bool,
  feedType: PropTypes.string,
  isForumTabsEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const params = getRouteParams(state);
  const getRouteCategory = () => getCategoryBySlug(state, params.categorySlug);
  const getFilterCategory = () => getCategoryBySlug(state, getCategoryFilter(state));
  const page = parseInt(params.page, 10) || 1;
  const category = getFilterCategory() || getRouteCategory() || { _id: null };
  const categorySlug = category.slug;
  const categoryId = category._id;
  const siteMemberId = ownProps.siteMemberId;
  const showMemberPosts = !!siteMemberId;
  const showAllPosts = categoryId === null && isNil(categorySlug);
  const showAsMainPage = showMemberPosts || showAllPosts || !!getFilterCategory();

  return {
    categorySlug: categorySlug || '',
    category,
    allPosts:
      showAllPosts && !showMemberPosts
        ? getSortedAllPosts(state)
        : getSortedPostsByCategoryIdAndSiteMemberId(
            state,
            categoryId || UNCATEGORIZED_POSTS,
            siteMemberId,
          ),
    currentPagePosts: getPostsByCategoryIdAndSiteMemberIdAndPage(
      state,
      page,
      showAllPosts ? UNCATEGORIZED_POSTS : categoryId,
      siteMemberId,
    ),
    newPostId: state.newPostMessage,
    entityCount: getEntityCount(
      state,
      siteMemberId ? `memberPosts.${siteMemberId}` : 'posts',
      categoryId || UNCATEGORIZED_POSTS,
    ),
    categoryId,
    isLoading: getIsCategoryLoading(state, categoryId),
    isPostsLoaded: getIsPostsLoaded(state, categoryId),
    isMemberAreaActive: isMemberAreaActive(state),
    page,
    isHeaderEnabled: getIsHeaderEnabled(state, host.style),
    layoutType: getLayoutType(state, host.style),
    sortingType: getCategoryPageSorting(state, host.style),
    showAllPosts,
    showMemberPosts,
    showAsMainPage,
    fetchPosts: actions.fetchCategoryPosts,
    setIsLoaded: actions.setIsLoaded,
    navigateWithinForum: actions.navigateWithinForum,
    emitCloseCategory: actions.emitCloseCategory,
    subcategories: getSubcategories(state, categoryId),
    hasActiveFilter:
      getCategoryFilter(state) !== '' || getPostTypeFilter(state) !== FILTER_SHOW_ALL,
    allCategories: getAllCategories(state),
    hasPP: hasPaidPlans(state),
    hasCategoryPP: hasCategoryPaidPlans(state, category.groups),
    isLiveSite: !isEditor(state) && !isPreview(state),
    feedType: getFeedType(state),
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withAuth,
  withDeviceType,
  withPermissions,
  withExperiment({
    isForumTabsEnabled: EXPERIMENT_FORUM_TABS,
    isPostCountIncreaseEnabled: EXPERIMENT_INCREASE_POST_LIST_ITEM_COUNT,
  }),
)(CategoryPage);
